// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/gallery-page.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-kursevi-page-js": () => import("./../../../src/templates/kursevi-page.js" /* webpackChunkName: "component---src-templates-kursevi-page-js" */),
  "component---src-templates-letnja-page-js": () => import("./../../../src/templates/letnja-page.js" /* webpackChunkName: "component---src-templates-letnja-page-js" */),
  "component---src-templates-materijal-page-js": () => import("./../../../src/templates/materijal-page.js" /* webpackChunkName: "component---src-templates-materijal-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

